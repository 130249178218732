<template>
    <div class="searchPage">
        <div class="searchBox">
            <div class="search">
                <search-input-home v-model="searchInput" :top="20" noSearch @search="submitSearch"></search-input-home>
            </div>

            <div class="filiterTop" v-if="fixed" :class="{ grideList: showType === 'gride' }">
                <el-header height="56px" class="listTop">
                    <radio-group :list="sortList" v-model="sort"></radio-group>

                    <span class="result" v-if="isChinese">
                        {{ $t('dang-qian-jie-guo-gong') }}{{ totalElements }}{{ $t('ge') }}{{ $i18n.t(radioName) }}
                    </span>

                    <span class="result" v-else> Current: {{ totalElements }} result(s) </span>

                    <el-radio-group v-model="showType" class="show">
                        <el-radio-button :label="item.value" v-for="item in listTypes" :key="item.value">
                            <div class="btn-info">
                                <span class="iconfont" v-html="item.icon"></span>
                                <span>{{ $i18n.t(item.label) }}</span>
                            </div>
                        </el-radio-button>
                    </el-radio-group>
                </el-header>
            </div>
        </div>
        <el-container class="filter-show" :class="{ grideContainer: showType === 'gride' }">
            <el-header height="56px" class="listTop">
                <!-- <el-radio-group v-model="sort" class="sort">
                    <el-radio-button :label="item.key" v-for="item in sortList">
                        {{ $i18n.t(item.name) }}
                    </el-radio-button>
                </el-radio-group> -->
                <radio-group :list="sortList" v-model="sort"></radio-group>
                <span class="result" v-if="isChinese">
                    {{ $t('dang-qian-jie-guo-gong') }}{{ totalElements }}{{ $t('ge') }}{{ $i18n.t(radioName) }}
                </span>

                <span class="result" v-else> Current: {{ totalElements }} result(s) </span>

                <el-radio-group v-model="showType" class="show">
                    <el-radio-button :label="item.value" v-for="item in listTypes" :key="item.value">
                        <div class="btn-info">
                            <span class="iconfont" v-html="item.icon"></span>
                            <span>{{ $i18n.t(item.label) }}</span>
                        </div>
                    </el-radio-button>
                </el-radio-group>
            </el-header>

            <el-main v-loading="loading">
                <el-row :gutter="30" v-if="showType == 'gride'" type="flex">
                    <el-col v-for="item in list" :key="item.id">
                        <brand-gride
                            :info="item"
                            v-if="searchInput.searchType === 'brand'"
                            :search="search"
                        ></brand-gride>
                        <product-gride
                            :info="item"
                            :isNew="false"
                            v-else-if="searchInput.searchType === 'product'"
                            :search="search"
                        ></product-gride>
                        <information-gride :info="item" :search="search" v-else></information-gride>
                    </el-col>
                </el-row>
                <template v-else>
                    <div class="row" v-for="item in list" :key="item.id">
                        <brand-row v-if="searchInput.searchType === 'brand'" :search="search" :info="item" />
                        <product-row
                            :info="item"
                            :isNew="false"
                            :search="search"
                            v-else-if="searchInput.searchType === 'product'"
                        ></product-row>
                        <information-row :info="item" :search="search" v-else></information-row>
                    </div>
                </template>

                <empty-page v-if="empty"></empty-page>
            </el-main>

            <el-footer>
                <el-pagination
                    @size-change="onSizeChange"
                    @current-change="onCurrentChange"
                    :current-page="page"
                    :page-sizes="[30, 40, 50]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalElements"
                >
                </el-pagination>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import BrandGride from '../components/brand/Gride';
import BrandRow from '../components/brand/Row';
import ProductGride from '../components/product/Gride';
import ProductRow from '../components/product/Row';
import InformationRow from '../components/news/Row';
import InformationGride from '../components/news/Gride';
import pageableList from '../mixins/pageableList';
import { InformationType, ProductSortType, BrandSortType, searchType } from '../utils/AppState';
import SearchInputHome from '../components/SearchInputHome';

export default {
    name: 'Search',
    mixins: [pageableList],
    data() {
        return {
            searchInput: {
                searchType: 'brand',
                searchVal: ''
            },
            colors: '',
            searchTypes: [...searchType].map(item => {
                return {
                    value: item[0],
                    name: item[1]
                };
            }),
            url: '',
            formType: {},
            fixed: false,
            pageSize: 30
        };
    },
    beforeRouteLeave(to, from, next) {
        if (['brandDetail', 'productDetail', 'NewsDetail'].includes(to.name)) {
            this.$store.commit('updateKeepAlive', ['Search']);
        } else {
            this.$store.commit('updateKeepAlive', []);
        }
        setTimeout(() => {
            next();
        }, 100);
    },
    watch: {
        'searchInput.searchType'() {
            this.$nextTick(() => {
                switch (this.searchInput.searchType) {
                    case 'brand':
                        this.url = '/vendorInfo/show1';
                        this.formType = {};
                        break;
                    case 'product':
                        this.url = '/product/show';
                        this.formType = {};
                        break;
                    default:
                        this.url = '/information/all';
                        this.formType = { body: 'json' };
                        break;
                }
                this.$nextTick(() => {
                    this.sort = this.sortList[0].key;
                    this.page = 1;
                    this.getData();
                });
            });
        },
        'searchInput.searchVal'() {
            this.searchKey = this.searchInput.searchVal;
        }
    },
    computed: {
        sortList() {
            if (this.searchInput.searchType === 'brand') {
                return [...BrandSortType].map(item => {
                    return {
                        key: item[0],
                        name: item[1]
                    };
                });
            } else if (this.searchInput.searchType === 'product') {
                return [...ProductSortType].map(item => {
                    return {
                        key: item[0],
                        name: item[1]
                    };
                });
            } else {
                return [...InformationType].map(item => {
                    return {
                        key: item[0],
                        name: item[1].name
                    };
                });
            }
        },
        radioName() {
            return searchType.get(this.searchInput.searchType);
        },
        searchLeft() {
            return (
                this.searchTypes.findIndex(item => {
                    return item.value === this.searchInput.searchType;
                }) *
                    68 +
                'px'
            );
        },
        listQuery() {
            if (this.searchInput.searchType === 'brand') {
                return {
                    vendorSort: this.sort
                };
            } else if (this.searchInput.searchType === 'product') {
                return {
                    productSort: this.sort
                };
            } else {
                return {
                    query: { type: this.sort, status: 'PASS' },
                    sort: 'top,desc;createdAt,desc;sort,desc'
                };
            }
        },
        routeQuery() {
            if (this.searchInput.searchType === 'brand') {
                return {
                    searchType: this.searchInput.searchType
                };
            } else if (this.searchInput.searchType === 'product') {
                return {
                    searchType: this.searchInput.searchType
                };
            } else {
                return {
                    searchType: this.searchInput.searchType,
                    query: '',
                    type: this.sort
                };
            }
        }
    },
    methods: {
        changeVale(e) {
            console.log(this.colors);
        },
        // scrollEvent() {
        //     if (document.documentElement.scrollTop >= 30) {
        //         this.fixed = true;
        //     } else {
        //         this.fixed = false;
        //     }
        // },
        setSearch(val) {
            this.searchInput.searchVal = val;
        }
    },
    components: {
        BrandGride,
        BrandRow,
        ProductGride,
        ProductRow,
        InformationGride,
        InformationRow,
        SearchInputHome
    },
    mounted() {
        if (this.$route.query.searchType) {
            this.searchInput.searchType = this.$route.query.searchType;
        }
        this.getData();
        console.log('进入search');
        // window.addEventListener('scroll', this.scrollEvent);
    },
    beforeDestroy() {
        // window.removeEventListener('scroll', this.scrollEvent);
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/pagebleTable';

.searchPage {
    // padding-top: 160px;
}
.searchBox {
    background-color: #fff;
    // position: fixed;
    // top: 90px;
    // left: 0;
    // right: 0;
    // z-index: 20;
    height: 160px;

    .filiterTop {
        // position: absolute;
        // bottom: -56px;
        // left: 0px;
        // right: 0px;
        background-color: #fff;

        .listTop {
            @include center-content();
        }
        &.grideList {
            .listTop {
                @include center-content();
            }
        }
    }
}
.search {
    @include center-content();
    box-sizing: border-box;
    .el-input-group {
        height: 56px;
        margin-top: 10px;
    }
    .radio-button {
        position: relative;
        z-index: 2;
    }
    .radio-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 16px;
        height: 10px;
        transform: translate(26px, 12px);
        transition: left ease-in-out 0.3s;
    }

    .search-content {
        width: 100%;
    }
}
.listTop {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    padding: 0;
    // width:min($--page-center , 100%)

    .result {
        font-size: 12px;
        color: rgba(188, 193, 204, 1);
        line-height: 17px;
        flex-grow: 1;
        margin: 0 1%;
        white-space: nowrap;
        text-align: right;
    }

    .el-button--default {
        background-color: transparentize($--color-warning, 0.94);
        color: $--color-warning;
        border-width: 0;
        border-top: 3px solid $--color-warning;
    }
}

@media screen and (min-width: 1690px) {
    .listTop {
        left: calc(50% - 745px);
        right: calc(50% - 745px);
    }
}

.el-container {
    padding-top: 30px;
    padding-bottom: 30px;
    @include center-content();

    &.grideContainer {
        @include center-content();
    }
}

.el-main {
    padding: 0 0 50px;
    position: relative;
    overflow: visible;

    .el-col {
        width: 16.66%;
        // padding-top: 30px;
        overflow: visible;
    }
}

@media screen and (max-width: 1450px) {
    .el-main {
        .el-col {
            width: 20%;
        }
    }
}
</style>
